export interface IAddCard {
  name?: string
  background: string
  alt?: string
  onClick: () => void
}

export const freeFamilyAdds: IAddCard[] = [
  {
    name: 'Free Family',
    background: '/assets/images/addCards/Add_Free_Family/Card_Free_Family.png',
    alt: '',
    onClick: () => window.open('https://www.free.fr/freebox/free-family/', '_blank')
  }
]

export const freeMobileAdds: IAddCard[] = [
  {
    name: 'Free Flex 1',
    background: '/assets/images/addCards/Add_Free_Flex/Card_Free_Flex_1.png',
    alt: '',
    onClick: () => window.open('https://mobile.free.fr/shop?orderBy=price_desc&paymentModes=loa', '_ blank')
  },
  {
    name: 'Free Flex 2',
    background: '/assets/images/addCards/Add_Free_Flex/Card_Free_Flex_2.png',
    alt: '',
    onClick: () => window.open('https://mobile.free.fr/shop?orderBy=price_desc&paymentModes=loa', '_ blank')
  },
  {
    name: 'Free Flex 3',
    background: '/assets/images/addCards/Add_Free_Flex/Card_Free_Flex_3.png',
    alt: '',
    onClick: () => window.open('https://mobile.free.fr/shop?orderBy=price_desc&paymentModes=loa', '_ blank')
  },
  {
    name: 'Free Flex 4',
    background: '/assets/images/addCards/Add_Free_Flex/Card_Free_Flex_4.png',
    alt: '',
    onClick: () => window.open('https://mobile.free.fr/shop?orderBy=price_desc&paymentModes=loa', '_ blank')
  },
  {
    name: 'Free Mobile 5G',
    background: '/assets/images/addCards/Add_Free_Mobile/Card_Plan_5G.png',
    alt: '',
    onClick: () => window.open('https://mobile.free.fr/fiche-forfait-free', '_ blank')
  },
  {
    name: 'Free Mobile Série Free',
    background: '/assets/images/addCards/Add_Free_Mobile/Card_Plan_Serie_Free.png',
    alt: '',
    onClick: () => window.open('https://mobile.free.fr/fiche-forfait-serie-free', '_ blank')
  }
]

export const freeboxAdds: IAddCard[] = [
  {
    name: 'Freebox Fibre',
    background: '/assets/images/addCards/Add_Freebox/Card_Fibre.png',
    alt: '',
    onClick: () => window.open('https://www.free.fr/freebox/fibre-optique/', '_ blank')
  },
  {
    name: 'Freebox Ultra Contenus',
    background: '/assets/images/addCards/Add_Freebox/Card_Freebox_Ultra_Contenus.png',
    alt: '',
    onClick: () => window.open('https://www.free.fr/freebox/freebox-ultra/', '_ blank')
  }
]
